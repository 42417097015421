import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS, GET_LIST, CREATE } from 'react-admin';
import { AUTH_KEEP_ALIVE, restClient } from './';

export default (parseConfig) => {

  const adminUsers = ['rweber@tyleranalytics.com', 'david.micksch@seazel.com', 'davidm@tyleranalytics.com', 'sosman@tyleranalytics.com'];

  const clearAuth = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('tokenExpiresAt');
    localStorage.removeItem('systemUserRecord');
    localStorage.removeItem('role');
  }
	
	const manageAuth = (type, params) => {
	    let headers = new Headers({ 
			'Content-Type': 'application/json', 
			'X-Parse-Application-Id': parseConfig['APP-ID'], 
			'X-Parse-REST-API-Key': parseConfig['REST-API-KEY'] 
		});
	    
	    if (type === AUTH_LOGIN) {
			const { username, password } = params;
			return restClient(CREATE, 'auth/login', { data: { email: username, password: password } })
	      .then(response => {
					localStorage.setItem('accessToken', response.data.token.accessToken);
					localStorage.setItem('refreshToken', response.data.token.refreshToken);
					localStorage.setItem('tokenExpiresAt', response.data.token.expiresIn);
          const role = adminUsers.includes(username) ? 'admin' : 'endUser';
          localStorage.setItem('role', role);
          
          return restClient(GET_LIST, 'systemUsers', { filter: { email: username } })
            .then(response => response.data)
            .then(systemUsers => {
              if (systemUsers.length === 1) {
                localStorage.setItem('systemUserRecord', JSON.stringify(systemUsers[0]));

                /*
                if (systemUsers[0].portalAdmin === 1) {
                  localStorage.setItem('role', 'admin');
                }
                */
                return Promise.resolve();
              }
            })
            .catch(response => {
              return Promise.reject(response.message);
            });
	      })
				.catch(response => {
					return Promise.reject(response.message);
				});
	    }
	    else if (type === AUTH_LOGOUT) {
        const systemUserRecord = localStorage.getItem('systemUserRecord');
        clearAuth();
        if(systemUserRecord) {
          return restClient(CREATE, 'auth/logout', { data: { email: JSON.parse(systemUserRecord).email } })
          .then(() => {
            return Promise.resolve();
          })
          .catch((error) => {
            return Promise.resolve();
          })
        }
        return Promise.resolve();
	    }
	    else if (type === AUTH_ERROR) {
			const { status } = params;
	      if (status === 209 || status === 401) {
          clearAuth();
          return Promise.reject();
	        }
	      return Promise.resolve();
	    }
	    else if (type === AUTH_CHECK) {
        const accessToken = localStorage.getItem('accessToken');
        const tokenExpiresAt = localStorage.getItem('tokenExpiresAt');
        if(accessToken && tokenExpiresAt)
        {
          const now =  new Date().toISOString();
          if(tokenExpiresAt >= now) {
            return Promise.resolve();
          }
        }
			  return Promise.reject();
	    }
	    else if (type === AUTH_KEEP_ALIVE) {
        const systemUserRecord = localStorage.getItem('systemUserRecord');
        const refreshToken = localStorage.getItem('refreshToken');
        if(systemUserRecord && refreshToken) {
          return restClient(CREATE, 'auth/refresh-token', { data: { email: JSON.parse(systemUserRecord).email, refreshToken: refreshToken } })
          .then(response => {
            localStorage.setItem('accessToken', response.data.accessToken);
            localStorage.setItem('refreshToken', response.data.refreshToken);
            localStorage.setItem('tokenExpiresAt', response.data.token.expiresIn);
            return Promise.resolve();
          })
          .catch(response => {
            return Promise.reject(response.message);
          });
			}
			else {
				return Promise.resolve();
			}
    } else if (type === AUTH_GET_PERMISSIONS) {
      const role = localStorage.getItem('role');
      return Promise.resolve(role);
  }
		else
			return Promise.reject('Unkown method');
	};
	
	return (type, params) => {
		return manageAuth(type, params);
	};
}
